import {Outlet} from 'react-router-dom'
import React from "react";
import Header from "./Header";
import Footer from "./Footer";

// bootstrap CSS
// <link rel="stylesheet" href="assets/css/bootstrap.min.css" type="text/css" media="all">
import 'bootstrap/dist/css/bootstrap.min.css';
//  <!-- carousel CSS -->
// 	<link rel="stylesheet" href="assets/css/owl.carousel.min.css" type="text/css" media="all">
import '../assets/css/owl.carousel.min.css'
// 	<!-- animate CSS -->
// 	<link rel="stylesheet" href="assets/css/animate.css" type="text/css" media="all">
import '../assets/css/animate.css'
// 	<!-- animated-text CSS -->
// 	<link rel="stylesheet" href="assets/css/animated-text.css" type="text/css" media="all">
import '../assets/css/animated-text.css'
// 	<!-- font-awesome CSS -->
// 	<link rel="stylesheet" href="assets/css/all.min.css" type="text/css" media="all">
import '../assets/css/all.min.css'
// 	<!-- font-flaticon CSS -->
// 	<link rel="stylesheet" href="assets/css/flaticon.css" type="text/css" media="all">
import '../assets/css/flaticon.css'
// 	<!-- theme-default CSS -->
// 	<link rel="stylesheet" href="assets/css/theme-default.css" type="text/css" media="all">
import '../assets/css/theme-default.css'
// 	<!-- meanmenu CSS -->
// 	<link rel="stylesheet" href="assets/css/meanmenu.min.css" type="text/css" media="all">
import '../assets/css/meanmenu.min.css'
// 	<!-- transitions CSS -->
// 	<link rel="stylesheet" href="assets/css/owl.transitions.css" type="text/css" media="all">
import '../assets/css/owl.transitions.css'
// 	<!-- venobox CSS -->
// 	<link rel="stylesheet" href="venobox/venobox.css" type="text/css" media="all">

// 	<!-- bootstrap icons -->
// 	<link rel="stylesheet" href="assets/css/bootstrap-icons.css" type="text/css" media="all">
import '../assets/css/bootstrap-icons.css'
// 	<!-- Main Style CSS -->
// 	<link rel="stylesheet" href="assets/css/style.css" type="text/css" media="all">
import '../assets/css/style.css'
// 	<!-- responsive CSS -->
// 	<link rel="stylesheet" href="assets/css/responsive.css" type="text/css" media="all">
import '../assets/css/responsive.css'




export default function Layout() {
  return (
    <>
      <div className="wrapper-area">
        <Header/>
        <Outlet/>
        <Footer/>
      </div>
    </>
  )
}