import React from 'react';

export default function OtherServicesBloc() {
  return (
    <>
      <div className="widget-categories-box">
        <div className="categories-title">
          <h4>Andere diensten </h4>
        </div>
        <div className="widget-categories-menu">
          <ul>
            <li>
              <a href="schoonmaak">
                Schoonmaak werkzaamheden
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                        </span>
              </a>
            </li>
            <li>
              <a href="stucenschilderwerk">
                Stuc- en schilderwerk
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                        </span>
              </a>
            </li>
            <li>
              <a href="timmerwerk">
                Timmerwerk
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                        </span>
              </a>
            </li>
            <li>
              <a href="sanitair">
                Alles rondom het sanitair
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                       </span>
              </a>
            </li>
            <li>
              <a href="isolatie">
                Isolatie
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                        </span>
              </a>
            </li>
            <li>
              <a href="Keukenbouw">
                Keukenbouw
                <span>
                          <i className="flaticon flaticon-right-arrow"></i>
                        </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}