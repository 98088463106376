import React, {useEffect} from 'react';

export default function Slider() {
  useEffect(()=> {
    if (typeof window !== 'undefined') {
      window.$('.slider_list').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed:1500,
        autoplayTimeout: 10000,
        dotsEach:true,
        dots: false,
        nav: true,
        navText: ["<i className='bi bi-arrow-left''></i>", "<i className='bi bi-arrow-right''></i>"],
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 1
          },
          992: {
            items: 1
          },
          1000: {
            items: 1
          },
          1920: {
            items: 1
          }
        }
      })
    }
  }, []);
  return (
    <>
      <div className="owl-carousel slider_list">
        <div className="slider-area d-flex align-items-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="slider-content">
                  <div className="slider-title">
                    <h1>NOOR HOLLAND</h1>
                    <h2>uw ideale partner</h2>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw, renovatie, gebouwbeheer of onderhoud.</p>
                  </div>
                  <div className="slider-button">
                    <a href="#">SEE PROJECTS <i className="bi bi-arrow-up-short"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}