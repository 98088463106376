import React from 'react'
import { Routes, Route } from 'react-router-dom'
import './App.css';
import Layout from "./layout/Layout";
import HomePage from "./pages/HomePage";
import PageConstruction from "./pages/PageConstruction";
import AproposPage from "./pages/AproposPage";
import ContactPage from "./pages/ContactPage";
import SchoonmaakServicePage from "./pages/SchoonmaakServicePage";
import StucenschilderwerkServicePage from "./pages/StucenschilderwerkServicePage";
import TimmerwerkServicePage from "./pages/TimmerwerkServicePage";
import SanitairServicePage from "./pages/SanitairServicePage";
import IsolatieServicePage from "./pages/IsolatieServicePage";
import KeukenbouwServicePage from "./pages/KeukenbouwServicePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />} >
        <Route index element={<HomePage />} />
        <Route path="over-ons" element={<AproposPage />} />
        <Route path="schoonmaak" element={<SchoonmaakServicePage />} />
        <Route path="stucenschilderwerk" element={<StucenschilderwerkServicePage />} />
        <Route path="timmerwerk" element={<TimmerwerkServicePage />} />
        <Route path="sanitair" element={<SanitairServicePage />} />
        <Route path="isolatie" element={<IsolatieServicePage />} />
        <Route path="keukenbouw" element={<KeukenbouwServicePage />} />
        <Route path="page-construction" element={<PageConstruction />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
    </Routes>
  );
}

export default App;
