import React from 'react';

export default function MapsBloc() {
  return (
    <>
      <div className="map-section">
        <div className="container">
          <div className="row mg-pt">
            <div className="col-lg-12 p-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2460.8323443996464!2d4.508069077141503!3d51.91876987190968!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c4333c0ff0047b%3A0xa300158b846cee1d!2sBartholomeus%20Everardus%20Molen%209%2C%203063%20PA%20Rotterdam%2C%20Pays-Bas!5e0!3m2!1sfr!2sma!4v1708038938774!5m2!1sfr!2sma"
                width="1920" height="520"  allowFullScreen="" aria-hidden="false"
                tabIndex="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}