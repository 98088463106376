import React from 'react';
import BannierInterne from "../components/BannierInterne";
import AproposBloc from "../components/AproposBloc";
import CounterBloc from "../components/CounterBloc";
import BrandBloc from "../components/BrandBloc";


export default function AproposPage() {
  return (
    <>
      <BannierInterne title="Over ons"/>
      <AproposBloc />
      <CounterBloc />
      <BrandBloc />
    </>
  )
}