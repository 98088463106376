import React from 'react';
import skill from '../assets/images/resource/skill.png';

export default function AproposHome() {
  return (
    <>
      <div className="skill-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="single-skill">
                <div className="skill-thumb">
                  <img src={skill} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-skill">
                <div className="section-title">
                  <h4>BOUW BEDRIJFSFUNCTIES</h4>
                  <h1>Wij maken de beste architectuur<br/>
                    Ontwerp met grote passie </h1>
                </div>
                <div className="skill-description">
                  <p>
                    Of het nu gaat om het creëren van isolatie, je droomkeuken, het renoveren van je badkamer,
                    het uitvoeren van timmerwerk of het verzorgen van schilderwerk, bij Noor Totaalbouw als aannemer
                    staan we voor je klaar met onze expertise en toewijding. Ons team heeft een tientallen jaren
                    ervaring en dat is terug te zien aan ons werk.
                  </p>
                </div>

                <div className="prossess-ber-plugin pt-20">
                  <span className="prosses-bar">Timmerwerk</span>
                  <div id="bar1" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill" data-percentage="94"></span>
                  </div>
                  <span className="prosses-bar">Keukenbouw</span>
                  <div id="bar2" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill my-class" data-percentage="80"></span>
                  </div>
                  <span className="prosses-bar">schilderwerk</span>
                  <div id="bar3" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill my-class2" data-percentage="88"></span>
                  </div>
                  <span className="prosses-bar">schoonmaak werkzaamheden</span>
                  <div id="bar4" className="barfiller">
                    <div className="tipWrap">
                      <span className="tip"></span>
                    </div>
                    <span className="fill my-class2" data-percentage="88"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}