import React from 'react';
import skill from '../assets/images/resource/skill.png';

export default function CounterBloc() {
  return (
    <>
      <div className="counter-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-6">
              <div className="counter-single-item-inner wow fadeInUp">
                <div className="counter-content">
                  <div className="counter-text">
                    <h1><span className="counter">39</span>K</h1>
                    <span></span>
                    <div className="counter-title">
                      <h4>Satisfied Customers</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-single-item-inner upper">
                <div className="counter-content">
                  <div className="counter-text">
                    <h1><span className="counter">670</span></h1>
                    <span></span>
                    <div className="counter-title">
                      <h4>Expert Members</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-single-item-inner wow fadeInUp">
                <div className="counter-content">
                  <div className="counter-text">
                    <h1><span className="counter">1.5</span>K</h1>
                    <span></span>
                    <div className="counter-title">
                      <h4>Modern Equipment</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="counter-single-item-inner upper">
                <div className="counter-content">
                  <div className="counter-text">
                    <h1><span className="counter">160</span></h1>
                    <span></span>
                    <div className="counter-title">
                      <h4>Tons Of Harvest</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}