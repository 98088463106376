import React from 'react';
import BannierInterne from "../components/BannierInterne";
import serviceDetails03 from "../assets/images/service/service-details03.png"
import serviceDetails04 from "../assets/images/service/service-details04.png"
import OtherServicesBloc from "../components/OtherServicesBloc";


export default function IsolatieServicePage() {
  return (
    <>
      <BannierInterne title="Diensten" breadcumb="Isolatie"/>
      <div className="service-detials-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="Constre-service-details-box">
                    <div className="Constre-service-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-content">
                      <div className="service-page-title">
                        <h1>Isolatie</h1>
                      </div>
                      <div className="serivce-details-desc">
                        <p>
                          Een energiezuinige gebouw is tegenwoordig de norm. Dit begint bij een goede basis in de isolatie.
                          Noor Holland kijkt met u mee welke vorm van isolatie bij uw ruimte past.
                          Wij komen daarom graag langs om vervolgens samen te kijken wat u wenst.
                          Onze experts maken vervolgens een plan waar we streven naar een kostenbesparende isolatiemethode.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-box">
                    <div className="service-details-icon">
                      <img src={serviceDetails03} alt=""/>
                    </div>
                    <div className="service-details-desc">
                      <div className="service-details-title">
                        <h4>24/7 uur ondersteuning</h4>
                      </div>
                      <div className="services-detials-desc">
                        <p>Functionaliteiten voortdurend aan toekomstige inlichtingen</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-box">
                    <div className="service-details-icon">
                      <img src={serviceDetails04} alt=""/>
                    </div>
                    <div className="service-details-desc">
                      <div className="service-details-title">
                        <h4>Op tijd klaar</h4>
                      </div>
                      <div className="services-detials-desc">
                        <p>Functionaliteiten voortdurend aan toekomstige inlichtingen</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-item">
                    <div className="service-details-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-contents">
                      <h3><a href="#">Design Architect</a></h3>
                      <ul className="service-details-list">
                        <li><i className="bi bi-check-lg"></i>Construction Equipments</li>
                        <li><i className="bi bi-check-lg"></i>Expert’s Engineers</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-item">
                    <div className="service-details-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-contents">
                      <h3><a href="#">Build Construction</a></h3>
                      <ul className="service-details-list">
                        <li><i className="bi bi-check-lg"></i>Construction Equipments</li>
                        <li><i className="bi bi-check-lg"></i>Expert’s Engineers</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 col-md-6">
              <OtherServicesBloc />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}