import React from 'react';


export default function ContactBloc() {
  return (
    <>
      <div className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-call-do-box">
                <div className="single-call-do-icon">
                  <i className="fas fa-phone"></i>
                </div>
                <div className="call-do-text">
                  <h3 className="call-do-title">Bel ons</h3>
                  <p><a href="tel:+31624377397">(+31) 6 24377397</a></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
            
              <div className="single-call-do-box">
                <div className="single-call-do-icon">
               
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="call-do-text">
                  <h3 className="call-do-title">E-Mail</h3>
                  <p><a href="mailto:info@noor-holland.nl">info@noor-holland.nl</a></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
            
              <div className="single-call-do-box">
                <div className="single-call-do-icon">
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <div className="call-do-text">
                  <h3 className="call-do-title">Plaats</h3>
                  <p> B Everardus Molen 9 3063 PA Rotterdam</p>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="row contact-center">*/}
          {/*  <div className="col-lg-12">*/}
          {/*    <div className="contact_from_box">*/}
          {/*      <div className="section-title style1 text-center">*/}
          {/*        <h4>CONTACT US</h4>*/}
          {/*        <h1>Get In Touch Contact Us</h1>*/}
          {/*      </div>*/}
          {/*      <form action="#" method="POST" id="dreamit-form">*/}
          {/*        <div className="row">*/}
          {/*          <div className="col-lg-6">*/}
          {/*            <div className="form_box">*/}
          {/*              <input type="text" name="name" placeholder="Name"/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <div className="col-lg-6">*/}
          {/*            <div className="form_box">*/}
          {/*              <input type="email" name="email" placeholder="Email Address"/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*          <div className="col-lg-12">*/}
          {/*            <div className="form_box">*/}
          {/*              <input type="text" name="sebject" placeholder="Subject"/>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*      */}
          {/*          <div className="col-lg-12">*/}
          {/*            <div className="form_box">*/}
					{/*					<textarea name="message" id="message" cols="30" rows="10"*/}
          {/*                    placeholder="Your Message"></textarea>*/}
          {/*            </div>*/}
          {/*            <div className="quote_button">*/}
          {/*              <button className="btn" type="submit">Send Message</button>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </form>*/}
          {/*      <div id="status"></div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}