import React from 'react';
import skill from '../assets/images/resource/skill.png';

export default function BrandBloc() {
  return (
    <>
      <div className="brand-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="owl-carousel brand_list">
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-1.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-2.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-3.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-4.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-5.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-6.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-7.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-brand">
                  <div className="brand-thumb">
                    <img src="assets/images/resource/brand-8.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}