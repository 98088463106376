import React from 'react';
import BannierInterne from "../components/BannierInterne";
import serviceDetails03 from "../assets/images/service/service-details03.png"
import serviceDetails04 from "../assets/images/service/service-details04.png"
import OtherServicesBloc from "../components/OtherServicesBloc";


export default function StucenschilderwerkServicePage() {
  return (
    <>
      <BannierInterne title="Diensten" breadcumb="Stuc- en schilderwerk"/>
      <div className="service-detials-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="Constre-service-details-box">
                    <div className="Constre-service-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-content">
                      <div className="service-page-title">
                        <h1>Stuc- en schilderwerk</h1>
                      </div>
                      <div className="serivce-details-desc">
                        <p>
                          Goed stuc werk is van groot belang. Hiermee maak je muren zo glad als je wilt en kan het echte
                          schilderwerk goed beginnen. Met schilderen maak je je muren helemaal compleet en tevens leg je
                          gelijk de
                          juiste beschermende basis laag aan. Zowel voor binnen als buitenhuis. Noor Holland zorgt dat
                          het schilderwerk voor extra dimensie zorgt in uw interieur, kantoor of welk oppervlak dan ook.
                        </p>
                        <p className="pb_30">
                          Niet alleen expertise is belangrijk maar ook de kwaliteit van het juiste materiaal. Een
                          prijsofferte
                          kunt u altijd vrijblijvend bij ons opvragen door simpelweg de vierkante meters door te geven.
                          Wij nemen graag uw schilderwerkzaamheden over en ontzorgen u door een kwaliteitsgarantie af te
                          geven.
                        
                        </p>
                      
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-box">
                    <div className="service-details-icon">
                      <img src={serviceDetails03} alt=""/>
                    </div>
                    <div className="service-details-desc">
                      <div className="service-details-title">
                        <h4>24/7 uur ondersteuning</h4>
                      </div>
                      <div className="services-detials-desc">
                        <p>Functionaliteiten voortdurend aan toekomstige inlichtingen</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-box">
                    <div className="service-details-icon">
                      <img src={serviceDetails04} alt=""/>
                    </div>
                    <div className="service-details-desc">
                      <div className="service-details-title">
                        <h4>Op tijd klaar</h4>
                      </div>
                      <div className="services-detials-desc">
                        <p>Functionaliteiten voortdurend aan toekomstige inlichtingen</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-item">
                    <div className="service-details-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-contents">
                      <h3><a href="#">Design Architect</a></h3>
                      <ul className="service-details-list">
                        <li><i className="bi bi-check-lg"></i>Construction Equipments</li>
                        <li><i className="bi bi-check-lg"></i>Expert’s Engineers</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="service-details-item">
                    <div className="service-details-thumb">
                      <img src="" alt=""/>
                    </div>
                    <div className="service-details-contents">
                      <h3><a href="#">Build Construction</a></h3>
                      <ul className="service-details-list">
                        <li><i className="bi bi-check-lg"></i>Construction Equipments</li>
                        <li><i className="bi bi-check-lg"></i>Expert’s Engineers</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4 col-md-6">
              <OtherServicesBloc />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}