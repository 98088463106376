import React from 'react';
import Slider from "../components/Slider";
import AproposHome from "../components/AproposHome";
import ServicesHome from "../components/ServicesHome";
import ProjectHome from "../components/ProjectHome";

export default function HomePage() {
  return (
    <>
      <Slider />
      <AproposHome />
      <ServicesHome />
      <ProjectHome />
    </>
  )
}