import React, {useEffect} from "react";
import logo from "../assets/images/logo-noor.png"


export default function Header() {
  useEffect(()=> {
    if (typeof window !== 'undefined') {
      window.$('.mobile-menu nav').meanmenu({
        meanScreenWidth: "991",
        meanMenuContainer: ".mobile-menu",
        meanMenuOpen: "<span></span> <span></span> <span></span>",
        onePage: false,
      });
    }
  }, []);
  
  return (
    <>
      
      {/*Start top-menu*/}
      <div class="top-menu">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="top-menu-left-site">
                <div class="top-menu-icon">
                  <ul>
                    <li class="new">uw betrouwbare partner in de bouw & schoonmaak</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="top-menu-right-site">
                <div class="top-menu-icon-list">
                  <ul>
                    <li class="mgr"><i class="bi bi-geo-alt"></i> B Everardus Molen 9 3063 PA Rotterdam</li>
                    <li><i class="bi bi-envelope"></i> info@noor-holland.nl</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/*End top-menu*/}

    {/*Start Main Menu Area*/}
      <div id="sticky-header" class="hendre_nav_manu">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo">
                <a class="logo_img" href="/" title="hendre">
                  <img src={logo} alt="logo"/>
                </a>
                <a class="main_sticky" href="/" title="hendre">
                  <img src={logo} alt="logo"/>
                </a>
              </div>
            </div>
            <div class="col-lg-10">
              <nav class="hendre_menu">
                <ul class="nav_scroll">
                  <li><a href="/">Startpagina</a></li>
                  <li><a href="#">Over</a></li>
                  <li><a href="#">Diensten <span><i class="fas fa-angle-down"></i></span></a>
                    <ul class="sub-menu">
                      <li><a href="schoonmaak">schoonmaak</a></li>
                      <li><a href="stucenschilderwerk">Stuc- en schilderwerk</a></li>
                      <li><a href="timmerwerk">Timmerwerk</a></li>
                      <li><a href="sanitair">Alles rondom het sanitair</a></li>
                      <li><a href="isolatie">Isolatie</a></li>
                      <li><a href="keukenbouw">Keukenbouw</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Portefeuille </a></li>
                  <li><a href="contact">Contact</a></li>
                </ul>
                <div class="header-button">
                  <a href="contact">KRIJG EEN OFFERTE <i class="bi bi-arrow-up-short"></i></a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      
      {/*Mobile Menu Area*/}
      <div class="mobile-menu-area sticky d-sm-block d-md-block d-lg-none ">
        <div class="mobile-menu">
          <nav class="hendre_menu">
            <ul class="nav_scroll">
              <li><a href="/">Startpagina</a></li>
              <li><a href="#">Over</a></li>
              <li><a href="#">Diensten <span><i className="fas fa-angle-down"></i></span></a>
                <ul className="sub-menu">
                  <li><a href="schoonmaak">schoonmaak</a></li>
                  <li><a href="stucenschilderwerk">Stuc- en schilderwerk</a></li>
                  <li><a href="timmerwerk">Timmerwerk</a></li>
                  <li><a href="sanitair">Alles rondom het sanitair</a></li>
                  <li><a href="isolatie">Isolatie</a></li>
                  <li><a href="keukenbouw">Keukenbouw</a></li>
                </ul>
              </li>
              <li><a href="#">Portefeuille </a></li>
              <li><a href="contact">Contact</a></li>
            </ul>
          </nav>
        </div>
      </div>
        {/* End Mobile Menu Area*/}
    </>
  )
}