import React from 'react';
import Contact from "../components/Contact";
import logo from "../assets/images/logo22.png"

export default function PageConstruction() {
  return (
    <>
      <div  className="service-area text-center">
        <img src={logo} height="250px" className="mw-100" />
      </div>
      
      <div className="service-area p-0 m-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title" style={{padding: "10px"}} >
                <h1 style={{color: "#FF5E15"}}>Noor holland</h1>
                <h2>Site onder constructie</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}