import React, {useEffect} from 'react';
import serviceTimmerwerk from '../assets/images/service/service-timmerwerk.jpg'
import serviceSanitaire from '../assets/images/service/service-sanitaire.jpg'
import serviceIsolation from '../assets/images/service/service-isolation.jpg'
import serviceKeukenbouw from '../assets/images/service/service-Keukenbouw.jpg'
import serviceSchilderwerk from '../assets/images/service/service-schilderwerk.jpg'
import serviceWerkzaamheden from '../assets/images/service/service-werkzaamheden.jpg'
import serIcon from '../assets/images/resource/ser-icon.png'
import serIcon1 from '../assets/images/resource/ser-icon-1.png'
import serIcon2 from '../assets/images/resource/ser-icon-2.png'
export default function ServicesHome() {
  
  useEffect(()=> {
    if (typeof window !== 'undefined') {
      window.$('.service_list').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed:2000,
        autoplayTimeout: 10000,
        dotsEach:true,
        dots: true,
        nav: false,
        navText: ["<i className='bi bi-arrow-left''></i>", "<i className='bi bi-arrow-right''></i>"],
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 1
          },
          992: {
            items: 2
          },
          1000: {
            items: 3
          },
          1920: {
            items: 3
          }
        }
      })
    }
  }, []);
  
  
  
  return (
    <>
      <div className="service-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h4>ONZE DIENSTEN</h4>
                <h1>Service die wij aanbieden<br/>
                  Onze klanten </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="owl-carousel service_list">
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceWerkzaamheden} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>schoonmaak werkzaamheden</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceSchilderwerk} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>Stuc- en schilderwerk</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceTimmerwerk} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>Timmerwerk</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceSanitaire} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>sanitair</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceIsolation} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>Isolatie</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-service-box">
                  <div className="service-thumb">
                    <img src={serviceKeukenbouw} alt="" />
                  </div>
                  <div className="service-content">
                    <h3>Keukenbouw</h3>
                    <p>NoorTotaalbouw is uw ideale partner voor onder andere in uw verbouw</p>
                    <div className="service-button">
                      <a href="/">Lees verder</a>
                    </div>
                    <div className="service-icon">
                      <img src={serIcon2} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}