import React, {useEffect} from 'react';
import project2 from '../assets/images/resource/project-2.jpg'
import project3 from '../assets/images/resource/project-3.jpg'
import project4 from '../assets/images/resource/project-4.jpg'
export default function ProjectHome() {
  
  useEffect(()=> {
    if (typeof window !== 'undefined') {
      window.$('.project_list').owlCarousel({
        loop: true,
        autoplay: true,
        smartSpeed:1500,
        autoplayTimeout: 10000,
        dotsEach:true,
        dots: false,
        nav: false,
        navText: ["<i class='bi bi-arrow-left''></i>", "<i class='bi bi-arrow-right''></i>"],
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 1
          },
          992: {
            items: 2
          },
          1000: {
            items: 3
          },
          1920: {
            items: 4
          }
        }
      })
    }
  }, []);
  
  
  
  return (
    <>
      <div className="project-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h4>LAATSTE PROJECTEN</h4>
                <h1>Onlangs voltooide werken</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="owl-carousel project_list">
              <div className="col-lg-12">
                <div className="single-project-box">
                  <div className="project-thumb">
                    <img src={project2} alt="" />
                      <div className="project-title">
                        <h3>Bouwconstructie</h3>
                        <span>sanitair</span>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-project-box">
                  <div className="project-thumb">
                    <img src={project3} alt="" />
                      <div className="project-title">
                        <h3>Bouwconstructie</h3>
                        <span>sanitair</span>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="single-project-box">
                  <div className="project-thumb">
                    <img src={project4} alt="" />
                      <div className="project-title">
                        <h3>Bouwconstructie</h3>
                        <span>Timmerwerk</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}