import React from 'react';
import skill from '../assets/images/resource/skill.png';

export default function AproposBloc() {
  return (
    <>
      <div className="about_area style-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about_left style-two">
                <div className="about_thumb wow fadeInUp ">
                  <img src="assets/images/resource/about_thumb02.png" alt=""/>
                    <div className="about-shape2 bounce-animate2">
                      <img src="assets/images/about/about-shape2.png" alt=""/>
                    </div>
                </div>
              </div>
            </div>
        
            <div className="col-lg-6 col-md-12">
              <div className="about_right wow fadeInUp">
                <div className="about-content">
                  <div className="section-title style-2 style-pb-10">
                    <h4>ABOUT US</h4>
                    <h1>We’re aspire to Enhance Your <br/>Stylish experiences</h1>
                    <p className="section-description">Monotonectally pontificate multidisciplinary methodologies
                      through 24 main business.
                      Conveniently develop future-proof niches and cross-platform markets. Synergistically
                      aggregate enable compelling relationships more principle evolve open-source in
                      whereas enterprise human capital</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="about-list">
                        <ul>
                          <li><i className="bi bi-check-circle"></i>Expertise Engineers</li>
                          <li><i className="bi bi-check-circle"></i>Expertise Engineers</li>
                        </ul>
                      </div>
                    </div>
                
                    <div className="col-lg-6">
                      <div className="about-list">
                        <ul>
                          <li><i className="bi bi-check-circle"></i>Expertise Engineers</li>
                          <li><i className="bi bi-check-circle"></i>Expertise Engineers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="about-btn style-two">
                    <a href="contact.html">Contact Us<i className="flaticon flaticon-right-arrow"></i></a>
                  </div>
                  <div className="about-author">
                    <div className="about-author-thumb">
                      <img src="assets/images/resource/about-author.jpg" alt=""/>
                    </div>
                    <div className="author-title">
                      <h3>John D. Alexon</h3>
                      <p>CEO & Founder</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}