import React from 'react';

export default function BannierInterne({title, breadcumb}) {
  return (
    <>
      <div className="breadcumb-area d-flex align-items-center">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-12">
              <div className="breadcumb-content">
                <h1>{title}</h1>
                <ul>
                  <li><i className="fas fa-house-user"></i><a href="index.html">Home</a></li>
                  <li>{breadcumb}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}