import React from 'react';
import BannierInterne from "../components/BannierInterne";
import ContactBloc from "../components/ContactBloc";
import MapsBloc from "../components/MapsBloc";


export default function ContactPage() {
  return (
    <>
      <BannierInterne title="Over ons" breadcumb="Over ons"/>
      <ContactBloc />
      <MapsBloc />
    </>
  )
}