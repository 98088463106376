import React from "react";
import logo from "../assets/images/logo-noor.png"


export default function Footer(){
  return(
    <>
      {/* Start Footer Section */}
     
  
      <div class="footer-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="widget widgets-company-info">
                <div class="elaction-logo">
                  <a class="logo_thumb" href="/" title="elaction">
                    <img src={logo} alt="images" />
                  </a>
                </div>
                <div class="company-info-desc">
                  <p>
                    Noor Totaalbouw is uw betrouwbare partner in de bouw & schoonmaak. Of het nu gaat om het creëren van
                    isolatie, je droomkeuken, het renoveren van je badkamer.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6">
              <div class="widget widget-nav-menu">
                <h4 class="widget-title">Social</h4>
                <div class="menu-quick-link-content">
                  <ul class="footer-menu">
                    <li><a href="#"> FACEBOOK </a></li>
                    <li><a href="#"> TWITTER </a></li>
                    <li><a href="#"> LINKEDIN </a></li>
                    <li><a href="https://www.instagram.com/noorholland2024/" target="_blank"> INSTAGRAM </a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="widget widget-nav-menu">
                <h4 class="widget-title"> Adres </h4>
                <div class="menu-quick-link-content">
                  <div class="post_blog_box upper">
                    <div class="footer_title">
                      <h3>B Everardus Molen 9 3063 PA Rotterdam </h3>
                      <h4>info@noor-holland.nl</h4>
                    </div>
                  </div>
                  <div class="post_blog_box">
                    <div class="footer_title">
                      <h5>+31 6 24377397 </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 pr-0">
              <div class="menu-quick-link-contact">
                {/* widget title */}
                <h4 class="widget-title upper"> Contact </h4>
                {/* company contact info  */}
                <div class="company-work-hour">
                  <div class="company-info">
                    <p>Laten we samen iets geweldigs bouwen! </p>
                  </div>
                </div>
                <div class="quick-link-button">
                  <a href="#">BEGIN <i class="bi bi-arrow-up-short"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* End Hendre Footer Section */}
  
  
      {/* Start copyright Section */}
      
      <div class="copyright-section d-flex align-items-center">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="copyright-icon">
                <ul>
                  <li><a href="#"> Over Ons </a></li>
                  <li><a href="#"> Onze Diensten </a></li>
                  <li><a href="#"> Contact </a></li>

                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="rights_site">
                <h4>Copyright ©Noor Holland 2024. Alle rechten voorbehouden</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* End copyright Section */}
    </>
  )
}